<template>
  <b-modal
    ref="payinDetailModal"
    centered
    size="lg"
    title="Chi tiết giao dịch"
    ok-only
    ok-title="Đóng"
  >
    <b-row cols="2">
      <b-col cols="6">
        <p class="payin-item">
          <span class="payin-item-title">Mã giao dịch:</span>
          <span class="payin-item-value">{{ payinDetail ? payinDetail.transactionId : '' }}</span>
        </p>
        <p class="payin-item">
          <span class="payin-item-title">Merchant:</span>
          <span class="payin-item-value">{{ payinDetail ? payinDetail.merchantName: '' }}</span>
        </p>
        <p class="payin-item">
          <span class="payin-item-title">Trạng thái giao dịch:</span>
          <span class="payin-item-value">{{ payinDetail ? mapStatus(payinDetail.status): '' }}</span>
        </p>
        <p class="payin-item">
          <span class="payin-item-title">Tên ngân hàng nhận:</span>
          <span class="payin-item-value">{{ payinDetail ? (payinDetail.receiver || 'Đang xử lý') : '' }}</span>
        </p>
        <p class="payin-item">
          <span class="payin-item-title">Số tài khoản nhận:</span>
          <span class="payin-item-value">{{ payinDetail ? (payinDetail.receiver || 'Đang xử lý') : '' }}</span>
        </p>
        <p class="payin-item">
          <span class="payin-item-title">Tên người nhận:</span>
          <span class="payin-item-value">{{ payinDetail ? (payinDetail.receiver || 'Đang xử lý') : '' }}</span>
        </p>
        <p class="payin-item">
          <span class="payin-item-title">Số tiền nhập:</span>
          <span class="payin-item-value">{{ payinDetail ? (payinDetail.detail.inputAmount.toLocaleString() || 0) : '' }}</span>
        </p>
        <p class="payin-item">
          <span class="payin-item-title">Nội dung:</span>
          <span class="payin-item-value">{{ payinDetail ? payinDetail.detail.content : '' }}</span>
        </p>
      </b-col>

      <b-col cols="6">
        <p class="payin-item">
          <span class="payin-item-title">Cổng thanh toán:</span>
          <span class="payin-item-value">QR Payment</span>
        </p>
        <p class="payin-item">
          <span class="payin-item-title">Nhà mạng:</span>
          <span class="payin-item-value">N/A</span>
        </p>
        <p class="payin-item">
          <span class="payin-item-title">Mã thẻ:</span>
          <span class="payin-item-value">N/A</span>
        </p>
        <p class="payin-item">
          <span class="payin-item-title">Seri:</span>
          <span class="payin-item-value">N/A</span>
        </p>
        <p class="payin-item">
          <span class="payin-item-title">Ngày nhận:</span>
          <span class="payin-item-value">{{ payinDetail ? formatDateTimeDb(payinDetail.createdAt) : '' }}</span>
        </p>
        <p class="payin-item">
          <span class="payin-item-title">Ngày xử lý:</span>
          <span class="payin-item-value">{{ payinDetail ? formatDateTimeDb(payinDetail.updatedAt) : '' }}</span>
        </p>
        <p class="payin-item">
          <span class="payin-item-title">Số tiền thực:</span>
          <span class="payin-item-value">{{ payinDetail.detail.realAmount ? payinDetail.detail.realAmount.toLocaleString() : 0 }}</span>
        </p>
        <p class="payin-item">
          <span class="payin-item-title">Đối soát:</span>
          <span class="payin-item-value">{{ payinDetail.detail.forControl ? payinDetail.detail.forControl.toLocaleString() : 0 }}</span>
        </p>
      </b-col>
    </b-row>

  </b-modal>
</template>

<script>
import { formatDateTimeDb } from '@/libs/timezone'
import {
  BModal,
  BRow,
  BCol,
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import { useOderPayInFilter } from '@/views/order-management/useOrder'

export default {
  name: 'PayinDetailModal',
  components: {
    BModal,
    BRow,
    BCol,
  },
  setup() {
    const payinDetailModal = ref(false)
    const payinDetail = ref({
      transactionId: '',
      merchantName: '',
      receiver: null,
      amountChange: 0,
      status: 'pending',
      fee: null,
      detail: {
        content: '',
        accountNumber: null,
        accountName: null,
        inputAmount: 0,
        realAmount: null,
        forControl: null,
        realReceiveAmount: null,
        qrCode: null,
      },
      createdAt: '',
      updatedAt: '',
    })

    const { mapStatus } = useOderPayInFilter()

    const openPayinDetailModal = payin => {
      payinDetail.value = payin
      payinDetailModal.value.show()
    }

    return {
      payinDetailModal,
      payinDetail,
      formatDateTimeDb,
      mapStatus,
      openPayinDetailModal,
    }
  },
}
</script>

<style scoped>
.payin-item-title {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
  /* or 70px */

  color: #000000;
}
.payin-item {
  display: flex;
  align-items: center;
}

.payin-item .payin-item-value {
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 30px;
  padding-left: 10px;
  /* or 70px */

  color: #000000;
}
</style>
