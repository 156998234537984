<template>
  <div>
    <!-- Stats Card Horizontal -->
    <b-overlay
      :show="isLoading"
      rounded="sm"
    >
      <b-card>
        <b-row>
          <b-col>
            <b-input-group class="input-group-merge">
              <b-input-group-prepend is-text>
                <feather-icon icon="SearchIcon" />
              </b-input-group-prepend>
              <b-form-input
                v-model="searchKeyWord"
                placeholder="Tìm kiếm"
              />
            </b-input-group>
          </b-col>

          <b-col>
            <b-form-datepicker
              v-model="dateFrom"
              local="vn"
              placeholder="Từ ngày"
              selected-variant="primary"
              :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
              style="margin-bottom: 0.2rem;"
            />
            <b-form-timepicker
              v-model="timeFrom"
              :disabled="dateFrom === null"
              locale="en"
              placeholder="From"
            />
          </b-col>

          <b-col>
            <b-form-datepicker
              v-model="dateTo"
              local="vn"
              placeholder="Đến ngày"
              selected-variant="primary"
              :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
              style="margin-bottom: 0.2rem;"
            />
            <b-form-timepicker
              v-model="timeTo"
              :disabled="dateTo === null"
              locale="en"
              placeholder="To"
            />
          </b-col>

          <b-col>
            <b-form-group>
              <v-select
                v-model="merchantName"
                placeholder="Tên merchant"
                label="username"
                :options="merchantNameOptions"
                :reduce="merchant => merchant.id"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row
          align-h="end"
          class="mb-2"
        >
          <b-col>
            <b-button
              variant="primary"
              class="float-right"
              :disabled="isTableLoading"
              @click="fetchOrderPayIn(true)"
            >
              Tìm kiếm
            </b-button>
            <b-button
              variant="outline-info"
              class="mr-1 float-right"
              :disabled="isLoading || isTableLoading"
              @click="exportExcel"
            >
              <feather-icon
                icon="DownloadIcon"
              />
              {{ $t('Xuất Excel') }}
            </b-button>
          </b-col>
        </b-row>
        <b-table
          small
          striped
          responsive
          show-empty
          :fields="fields"
          :items="items"
          :busy="isTableLoading"
        >
          <template #empty="scope">
            <b-row>
              <b-col>
                <b-alert
                  show
                  variant="info"
                  class="text-center"
                >
                  Không có dữ liệu
                </b-alert>
              </b-col>
            </b-row>
          </template>

          <template #table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle" />
              <strong>Loading...</strong>
            </div>
          </template>
          <!-- A virtual column -->
          <template #cell(#)="data">
            {{ data.index + 1 }}
          </template>

          <template #cell(transactionId)="data">
            <span
              class="text-nowrap transaction-item"
            >{{ data.value }}</span>
          </template>

          <template #cell(receiver)="data">
            <span class="text-nowrap">{{ data.value ? data.value : data.item.detail.mobileOperator }}</span>
          </template>

          <template #cell(detail.content)="data">
            <span
              v-if="data.value"
              class="text-nowrap"
            >
              {{ data.value }}
            </span>
            <span
              v-else
              class="text-nowrap"
            >
              {{ data.item.detail.cardCode }} <br> {{ data.item.detail.cardSeri }}
            </span>
          </template>

          <template #cell(createdAt)="data">
            <span class="text-nowrap">{{ formatDateTimeDb(data.value) }}</span>
          </template>

          <template #cell(order_status)="data">
            <span class="text-nowrap">{{ data.value || 'Đang xử lý' }}</span>
          </template>

          <template #cell(updatedAt)="data">
            <span class="text-nowrap">{{ formatDateTimeDb(data.value) }}</span>
          </template>

          <template #cell(detail.inputAmount)="data">
            <span class="text-nowrap">{{ data.value.toLocaleString() }}</span>
          </template>

          <template #cell(detail.realAmount)="data">
            <span class="text-nowrap">{{ data.value.toLocaleString() || 0 }}</span>
          </template>

          <template #cell(detail.forControl)="data">
            <span class="text-nowrap">{{ data.value.toLocaleString() || 0 }}</span>
          </template>

          <template #cell(fee)="data">
            <span class="text-nowrap">{{ data.value.toLocaleString() || 0 }}</span>
          </template>

          <template #cell(detail.realReceiveAmount)="data">
            <span class="text-nowrap">{{ data.value.toLocaleString() || 0 }}</span>
          </template>

          <template #cell(status)="data">
            <b-badge :variant="mapVariant(data.value)">
              {{ mapStatus(data.value) }}
            </b-badge>
          </template>

          <!-- Optional default data cell scoped slot -->
          <template #cell()="data">
            {{ data.value }}
          </template>
        </b-table>

        <b-row>
          <b-col class="d-flex justify-content-md-end">
            <b-pagination
              v-model="pagination.current_page"
              :total-rows="pagination.total"
              :per-page="pagination.per_page"
              first-text="Trang đầu"
              prev-text="Trang trước"
              next-text="Trang tiếp"
              last-text="Trang cuối"
              @change="changePage"
            />
          </b-col>
        </b-row>
      </b-card>
    </b-overlay>

    <payin-detail-modal ref="payinDetailModal" />
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BInputGroup,
  BInputGroupPrepend,
  BFormInput,
  BButton,
  BPagination,
  BFormDatepicker,
  BFormGroup,
  BTable,
  BBadge,
  BAlert,
  BOverlay,
  BSpinner,
  BCard,
  BFormTimepicker,
} from 'bootstrap-vue'
import { onMounted, ref } from '@vue/composition-api'
import vSelect from 'vue-select'
import dateFormat from 'dateformat'
// eslint-disable-next-line import/no-cycle
import { formatDateTimeDb } from '@core/utils/utils'
import PayinDetailModal from '@/views/order-management/PayinDetailModal.vue'
import {
  useMasterFilter,
  // eslint-disable-next-line import/named
  useUsdtFilter,
} from './useOrder'

export default {
  name: 'Payin',
  components: {
    PayinDetailModal,
    BRow,
    BCol,
    BInputGroup,
    BFormInput,
    BInputGroupPrepend,
    BFormDatepicker,
    BFormGroup,
    BButton,
    BPagination,
    BTable,
    BBadge,
    BAlert,
    BOverlay,
    BSpinner,
    BCard,
    vSelect,
    BFormTimepicker,
  },
  setup() {
    const payinDetailModal = ref(null)
    const isLoading = ref(false)
    const isTableLoading = ref(false)

    const timeFrom = ref(null)
    const timeTo = ref(null)

    const parseDateTime = (date, time, type) => {
      let timeParse
      let second = 0
      if (!date) return null
      if (type === 'from') {
        timeParse = time || '00:00'
      } else {
        timeParse = time || '23:59'
        second = 59
      }
      const dateArr = date.split('-')
      const timeArr = timeParse.split(':')
      return dateFormat(new Date(dateArr[0], dateArr[1] - 1, dateArr[2], timeArr[0], timeArr[1], second), 'yyyy-mm-dd HH:MM:ss')
    }

    const {
      fields,
      items,
      pagination,
      searchKeyWord,
      handleSearch,
      mapStatus,
      mapVariant,
    } = useUsdtFilter()

    const {
      dateFrom,
      dateTo,
      merchantName,
      merchantNameOptions,
      fetchMerchantNameOptions,
    } = useMasterFilter()

    const fetchOrderPayIn = (isSearch = false) => {
      isTableLoading.value = true
      handleSearch({
        payinType: 'usdt',
        searchKeyWord: searchKeyWord.value,
        page: isSearch ? 1 : pagination.value.current_page,
        dateFrom: parseDateTime(dateFrom.value, timeFrom.value, 'from'),
        dateTo: parseDateTime(dateTo.value, timeTo.value, 'to'),
        merchantId: merchantName.value,
        isHistory: true,
      }).then(res => {
        const { data, meta } = res
        items.value = data
        pagination.value = meta.pagination
        isTableLoading.value = false
      })
    }

    const changePage = page => {
      pagination.value.current_page = page
      fetchOrderPayIn()
    }

    onMounted(() => {
      fetchOrderPayIn()
      fetchMerchantNameOptions()
    })

    return {
      merchantName,
      merchantNameOptions,
      dateFrom,
      dateTo,
      timeFrom,
      timeTo,

      fields,
      items,
      pagination,
      searchKeyWord,
      isLoading,
      isTableLoading,

      fetchOrderPayIn,
      formatDateTimeDb,
      mapStatus,
      payinDetailModal,
      changePage,
      mapVariant,
      parseDateTime,
    }
  },
  methods: {
    exportExcel() {
      if (!this.isTimeVerify()) {
        this.$swal(this.$t('Thông báo'), this.$t('Chưa chọn thời gian hoặc khoảng thời gian lớn hơn 30 ngày'), 'warning')
        return
      }
      this.isLoading = true
      this.$http.get('pay-orders/export', {
        responseType: 'blob',
        params: {
          search: this.search ? this.search : null,
          merchantName: this.merchantName ? this.merchantName : null,
          date_from: this.dateFrom ? this.dateFrom : null,
          date_to: this.dateTo ? this.dateTo : null,
          type: 'payin_usdt',
        },
        headers: {
          lang: 'vi',
        },
      })
        .then(response => {
          const blob = new Blob([response.data], { type: 'application/xls' })
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download = `history-export-${this.dateFrom}-${this.dateTo}.xlsx`
          link.click()
          URL.revokeObjectURL(link.href)
        })
        .catch(console.error)
        .finally(() => {
          this.isLoading = false
        })
    },
    isTimeVerify() {
      if (this.dateFrom && this.dateTo) {
        const dateFrom = new Date(this.dateFrom)
        const dateTo = new Date(this.dateTo)
        const diffTime = Math.abs(dateTo - dateFrom)
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
        return diffDays <= 30
      }
      return false
    },
  },
}
</script>

<style scoped>
.transaction-item {
  color: #3A57E8;
  cursor: pointer;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.vs__selected-options {
  flex-wrap: nowrap;
  max-width: calc(100% - 25px); /* change this to `- 40px` if you're supporting a `clearable` field; I was not */
}

.vs__selected {
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
  overflow: hidden;
}
#swal2-title {
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.2;
  color: #212529;
  text-align: center;
  margin: 1em 0 1rem;
}
</style>
